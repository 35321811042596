import axios from "axios";
import { required, maxLength, email } from "vuelidate/lib/validators";

export const ProfileMixin = {
  validations: {
    profileDataEdit: {
      first_name: {
        maxLength: maxLength(25),
        required,
      },
      last_name: {
        maxLength: maxLength(25),
        required,
      },
      mobile: {
        required,
        maxLength: maxLength(50),
        phoneNum(value) {
          return /^(\+?)[0-9]+$/.test(value); // checks for +
          // checks for 0-9
        },
      },
      email: {
        required,
        maxLength: maxLength(255),
        email,
      },
      gender: {},
      date_of_birth: {},
      country_code_id: {},
      country_id: {
        required,
      },
    },
  },
  methods: {
    getData(submit = false) {
      // to disable btn untile return from backend
      let id = "";
      if (localStorage.id != "null") {
        id = "/" + localStorage.id;
      }
      if (submit == true) {
        this.submitted = true;
        axios
          .post(this.getApiUrl + "/users/update" + id, this.profileDataEdit, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          })
          .then((response) => {
            // activate submit btn again
            this.submitted = false;
            this.$v.$reset();
            // console.log("mixins");
            // when submit successfully
            if (!response.data.status.error) {
              //   localStorage.id = response.data.data.id;
              this.editable = false;
              this.snack = true;
              this.snackColor = "green";
              if (this.currentAppLocale == "ar") {
                this.snackText = "تم تحديث البيانات بنجاح";
              } else {
                this.snackText = "Data updated successfully";
              }

              axios
                .get(this.getApiUrl + "/users/show" + id, {
                  headers: {
                    Authorization: "Bearer " + localStorage.token,
                    //the token is a variable which holds the token
                  },
                })
                .then((response) => {
                  this.profileData = response.data.data;
                  this.profileDataEdit = response.data.data;
                  // this.validation_errors = [];
                });
              // if success route to dashboard
              // this.$router.push("/profile");
              window.location.reload();
            } else {
              this.validation_errors = response.data.status.validation_errors;
              this.error = response.data.status.error;
            }
          });
      }
      if (submit == false) {
        if (id != "null") {
          axios
            .get(this.getApiUrl + "/users/show" + id, {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            })
            .then((response) => {
              this.profileData = response.data.data;
              this.profileDataEdit = response.data.data;
              this.validation_errors = [];
            });

          // edit api
          // axios
          //   .get(this.getApiUrl + "/users/update" + id, {
          //     headers: {
          //       Authorization: "Bearer " + localStorage.token
          //       //the token is a variable which holds the token
          //     }
          //   })
          //   .then(response => {
          //     this.profileDataEdit = response.data.data;
          //   });
        }
      }
    },

    cancel() {
      // edit api
      let id = "/" + localStorage.id;
      this.editable = false;
      this.validation_errors = [];

      if (id != "null") {
        axios
          .get(this.getApiUrl + "/users/show" + id, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          })
          .then((response) => {
            this.profileData = response.data.data;
            this.profileDataEdit = response.data.data;
          });
      }
    },
  },
};
